<template lang="html">
  <v-container fluid pa-0>
    <v-row>
      <v-col cols="12"><v-patient-bar :value="currentHN" @patientLoaded="onPatientLoaded" @patientUnloaded="onPatientUnloaded"></v-patient-bar></v-col>
    </v-row>
    <v-row v-if="!currentPatient" dense>
      <v-col>
        <v-card>
          <v-card-text>
            <v-container fluid pa-0>
              <v-row no-gutters>
                <v-col>
                  <v-form-pad ref="formPatient" v-model="formData.patient">
                    <template v-slot="{data, rules}">
                      <v-card flat>
                        <v-card-title primary-title class="py-1">
                          <v-container pa-0 fluid>
                            <v-row dense>
                              <v-col cols="12" md="4" align-self="center">
                                <span class="headline">ลงทะเบียนผู้ป่วยใหม่ก่อนเข้ารับบริการ</span>
                              </v-col>
                              <v-col cols="12" md="5" align-self="center">
                                <v-radio-group v-model="data.personIdType" row>
                                  <v-radio class="mb-0" label="ผู้ป่วยชาวไทย" :value="1"></v-radio>
                                  <v-radio label="ผู้ป่วยชาวต่างชาติ" :value="2"></v-radio>
                                </v-radio-group>
                              </v-col>
                              <v-col cols="12" md="3">
                                <v-master-field v-model="data.nationality" label="สัญชาติ"
                                  :groupKey="$store.getters.getGroupKey('nationality')"
                                  :rules="[rules.requireIf(isForeigner)]"
                                  :disabled="isThai"
                                ></v-master-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-title>
                        <v-card-text class="py-1">
                          <v-container pa-0 fluid>
                            <v-row dense align="center">
                              <v-col cols="12" md="6" v-if="isThai">
                                <v-text-field v-model="data.personId" label="เลขประจำตัวประชาชน"
                                  :rules="[rules.require(),rules.numeric('เลขบัตรประชาชนต้องเป็นตัวเลขเท่านั้น'),rules.length(13,'เลขบัตรประชาชนต้องมี 13 หลัก')]"
                                  :append-outer-icon="(!config.isLoadDataFromIDCard && !data.personIdVerified) ? 'get_app' : null"
                                  @click:append-outer="getDataIDCard"
                                  :loading="config.isLoadDataFromIDCard"
                                  :readonly="data.personIdVerified"
                                  autofocus
                                >
                                  <template v-slot:append>
                                    <v-chip color="teal" text-color="white" v-if="data.personIdVerified">
                                      <v-avatar><v-icon>check_circle</v-icon></v-avatar>
                                      Confirmed
                                    </v-chip>
                                  </template>
                                </v-text-field>

                              </v-col>
                              <v-col cols="12" md="6" v-if="isForeigner">
                                <v-row dense>
                                  <v-col cols="8">
                                    <v-text-field v-model="data.personId" label="Passport No."
                                      :rules="[rules.require()]"
                                      auto-focus
                                    >
                                      <template v-slot:append>
                                        <v-chip color="teal" text-color="white" v-if="data.personIdVerified">
                                          <v-avatar><v-icon>check_circle</v-icon></v-avatar>
                                          Confirmed
                                        </v-chip>
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="4">
                                    <v-date-field v-model="data.personIdDetail.passportExpiryDate" append-icon="event" label="Passport expiry date" :rules="[rules.require()]"></v-date-field>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col md="6">
                                <template v-if="isForeigner">
                                  <v-row dense>
                                    <v-col cols="8">
                                      <v-text-field v-model="data.personIdDetail.visaNo" label="Visa No."></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                      <v-date-field v-model="data.personIdDetail.visaExpiryDate" append-icon="event" label="Visa expiry date"></v-date-field>
                                    </v-col>
                                  </v-row>
                                </template>
                                <template v-else>
                                  <v-btn @click="getDataMOPH" :loading="config.isLoadDataFromIDCard" color="primary" v-if="!data.personIdVerified && data.personId && data.personId.length==13">ดึงข้อมูล MOPH</v-btn>
                                </template>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-patient-name v-model="formData.name[0]" name-type="TH" :disabled="isForeigner" :readonly="data.personIdVerified" required></v-patient-name>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-patient-name v-model="formData.name[1]" name-type="EN" :readonly="data.personIdVerified" :required="isForeigner" english></v-patient-name>
                              </v-col>
                              <v-col cols="6" md="2">
                                <v-date-field v-model="data.dateOfBirth" prepend-icon="event" label="วัน-เดือน-ปี เกิด"
                                  :rules="[rules.require()]"
                                  birth-date
                                ></v-date-field>
                              </v-col>
                              <v-col cols="6" md="2">
                                <v-radio-group v-model="data.sex" row label="เพศ" :rules="[rules.require()]">
                                  <v-spacer></v-spacer>
                                  <v-radio class="mr-1" label="ชาย" value="1"></v-radio>
                                  <v-radio label="หญิง" value="2"></v-radio>
                                  <v-spacer></v-spacer>
                                </v-radio-group>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field v-model="data.primaryMobileNo" label="เบอร์โทรศัพท์มือถือ" :rules="[rules.require()]"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field v-model="data.primaryEmail" label="Email"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                      <v-card flat v-if="isThai">
                        <v-card-title primary-title class="py-1">
                          <span class="headline">ที่อยู่ตามบัตรประชาชน</span>
                        </v-card-title>
                        <v-card-text class="py-1">
                          <v-address v-model="formData.address[1]" address-type="census" @input="copyAddress" required key="census"></v-address>
                        </v-card-text>
                      </v-card>
                      <v-card flat>
                        <v-card-title primary-title class="py-1">
                          <span class="headline">ที่อยู่ปัจจุบัน&nbsp;</span><span class="headline" v-if="isForeigner">(ในประเทศไทย)</span>
                          <v-checkbox label="ตามบัตรประชาชน" v-model="config.isContactSameAsCensus" v-if="isThai"></v-checkbox>
                        </v-card-title>
                        <v-card-text class="py-1">
                          <v-address v-model="formData.address[0]"
                            address-type="contact"
                            :disabled="config.isContactSameAsCensus && isThai"
                            required key="contact"
                          ></v-address>
                        </v-card-text>
                      </v-card>
                      <v-card flat v-if="isForeigner">
                        <v-card-title primary-title class="py-1">
                          <span class="headline">ที่อยู่ตามภูมิลำเนา</span>
                        </v-card-title>
                        <v-card-text class="py-1">
                          <v-address v-model="formData.address[1]" address-type="foreign" foreign-address  key="foreign"></v-address>
                        </v-card-text>
                      </v-card>
                    </template>
                  </v-form-pad>
                  <v-btn color="primary" @click.native="savePatientAndNext" :loading="config.isSavingPatient">บันทึกข้อมูล</v-btn>&nbsp;
                  <v-btn text @click.native="reset">ล้างข้อมูล</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="currentPatient" dense>
      <v-col cols="12">
        <v-covid19-vaccine-bar :hn="currentPatient.hn" showPrintPatientSlip>
          <v-btn color="primary" @click="printConsent">พิมพ์ Consent Form</v-btn>
        </v-covid19-vaccine-bar>
      </v-col>
      <v-col cols="12">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab>Consent Form</v-tab>
            <v-tab>Vital Sign</v-tab>
            <v-tab>ประวัติ</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item>
              <v-document-editor ref="consentForm" :hn="currentPatient.hn" templateCode="cv19-vaccine-consent" :findLatest="findLatestObject" approveOnSave></v-document-editor>
            </v-tab-item>
            <v-tab-item>
              <v-patient-vitalsign :hn="currentPatient.hn"></v-patient-vitalsign>
            </v-tab-item>
            <v-tab-item>
              <v-document-viewer :documentQuery="{hn: currentPatient.hn}"></v-document-viewer>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { isUndefined,isEmpty,isArray,cloneDeep,merge } from 'lodash'
import getIDCard from '@/modules/getIDCard'
import patientLoader from '@/components/mixins/patientLoader'
import printDocument from '@/components/mixins/printDocument'
import helperDateTime from 'tantee-common/lib/helperDateTime'
import api from 'tantee-common/lib/api'

export default {
  mixins: [patientLoader,printDocument],
  data: ()=>({
    formData: {
      patient: {
        personIdType: 1,
        personIdVerified: false,
        personIdDetail: {},
      },
      name: [],
      address: [],
    },
    config: {
      isContactSameAsCensus: true,
      isLoadingIDCard: false,
      isLoadDataFromIDCard: false,
      isSavingPatient: false,
    },
    currentHN: undefined,
  }),
  watch: {
    'config.isContactSameAsCensus': function() {
      this.copyAddress()
    },
    isThai: function(newValue) {
      if (newValue) {
        this.formData.patient.nationality = undefined
      }
    },
    isForeigner: function(newValue) {
      if (newValue) {
        this.$set(this.formData.name,0,{})
      }
    },
  },
  methods: {
    validate() {
      return this.$refs.formPatient.validate()
    },
    reset() {
      this.currentHN = null
      this.currentPatient = undefined
      this.$nextTick(()=>{
        this.$refs.formPatient.reset()
        this.formData = {
          patient: {
            personIdType: 1,
            personIdDetail: {},
          },
          name: [],
          address: [],
        }
        this.config = {
          isContactSameAsCensus: true,
          isLoadingIDCard: false,
          isLoadDataFromIDCard: false,
        }
      })
    },
    copyAddress() {
      if (this.config.isContactSameAsCensus && !this.isForeigner) {
        this.formData.address[0] = cloneDeep(this.formData.address[1])
      }
    },
    savePatientAndNext() {
      this.config.isSavingPatient = true
      if (this.validate()) {
        this.$confirm('ต้องการบันทึกข้อมูลและสร้างผู้ป่วยใหม่').then((res)=>{
          if (res) {
            let loading = this.$loading('Saving')
            api.post(['Patient','PatientController','createPatient'],this.formData).then((returnData)=> {
              if (returnData.success) {
                this.currentHN = returnData.data.hn
              } else {
                returnData.errorTexts.forEach((item)=>{
                  this.$store.dispatch('addAlertItem',{
                    alertText: item.errorText,
                    alertType: 'error'
                  })
                })
              }
            }).finally(()=>{
              loading.done()
              this.config.isSavingPatient = false
            })
          } else {
            this.config.isSavingPatient = false
          }
        })
      } else {
        this.config.isSavingPatient = false
      }
    },
    getDataIDCard() {
      this.config.isLoadDataFromIDCard = true
      getIDCard().then((cardData)=>{
        if (!isUndefined(cardData.address) && isArray(cardData.address)) cardData.address.unshift({})
        this.formData = merge({},this.formData,cardData)
        if (this.config.isContactSameAsCensus && !this.isForeigner) {
          this.formData.address[0] = cloneDeep(this.formData.address[1])
        }

        if (this.formData && this.formData.patient && this.formData.patient.personId) {
          api.post(['Patient','PatientController','getPatient'],{hn: this.formData.patient.personId}).then((returnData)=>{
            if (returnData.success) {
              this.currentHN = returnData.data.hn
            }
          }).catch((e)=>void e)
        }
      })
      .catch((e)=>void e)
      .finally(()=>{
        this.config.isLoadDataFromIDCard = false
      })
    },
    getDataMOPH() {
      if (this.formData && this.formData.patient && this.formData.patient.personId && this.formData.patient.personId.length==13) {
        this.config.isLoadDataFromIDCard = true
        api.post(['Covid19','VaccineController','getTargetToPatient'],{personId:this.formData.patient.personId}).then((mophData)=>{
          if (mophData.success && mophData.data) {
            if (!isUndefined(mophData.data.address) && isArray(mophData.data.address)) mophData.data.address.unshift({})
            this.formData = merge({},this.formData,mophData.data)
            if (this.config.isContactSameAsCensus && !this.isForeigner) {
              this.formData.address[0] = cloneDeep(this.formData.address[1])
            }
          }


          if (this.formData && this.formData.patient && this.formData.patient.personId) {
            api.post(['Patient','PatientController','getPatient'],{hn: this.formData.patient.personId}).then((returnData)=>{
              if (returnData.success) {
                this.currentHN = returnData.data.hn
              }
            }).catch((e)=>void e)
          }
        })
        .catch((e)=>void e)
        .finally(()=>{
          this.config.isLoadDataFromIDCard = false
        })
      }
    },
    onPatientLoaded(patient) {
      this.currentPatient = patient
      this.$emit('patientLoaded', patient)
    },
    onPatientUnloaded(hn) {
      this.reset()
    },
    printConsent() {
      this.$refs.consentForm.printCurrentDocument()
    }
  },
  computed: {
    isForeigner() {
      return this.formData.patient.personIdType!=1
    },
    isThai() {
      return this.formData.patient.personIdType==1
    },
    isPersonIdDetail() {
      return !isEmpty(this.formData.patient.personIdDetail)
    },
    findLatestObject() {
      return {
        'whereDate#created_at#>=': helperDateTime.now().subtract(1,'days').format('YYYY-MM-DD')
      }
    }
  }
}
</script>

<style lang="css">
</style>
